import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { db, functions, storage } from '@/firebase'
import moment from 'moment'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
		items: null,
		extras: null,
		products: null,
		categories: null,
		customers: null,
		discounts: null,
		shops: null,
		merchants: null,
		orders: null,
		expenseCategories: null,
		expenses: null,
		startDate: null,
		endDate: null,
		diffDays: 'today',
		toDay: moment().format('YYYYMMDD'),
		yesterDay: moment().subtract(1, "days").format('YYYYMMDD'),
		thisMonthStart: moment().startOf('month').format('x'),
		thisMonthEnd: moment().endOf('month').format('x'),
		lastMonthStart: moment().subtract(1, 'months').startOf('month').format('x'),
		lastMonthEnd: moment().subtract(1, 'months').endOf('month').format('x'),
	},
    mutations: {
		...vuexfireMutations,
	},
    actions: {
		bindCollection: firestoreAction((context, { col }) => {
			if (context.state[col]) return true
			return context.bindFirestoreRef(col, db.collection(col))
		}),

		bindCollectionByMerchant: firestoreAction((context, { col, merchantId }) => {
			if (context.state[col]) return true
			return context.bindFirestoreRef(col, db.collection(col).where('merchantId', '==', merchantId))
		}),

		bindDocument: firestoreAction((context, { col, id, target }) => {
			return context.bindFirestoreRef(target, db.collection(col).doc(id))
		}),

        bindRef: firestoreAction((context, { ref, target }) => {
            if (context.state[target]) return true
			return context.bindFirestoreRef(target, ref)
        }),

		bindOrdersDashboard({ state, dispatch }) {
			state.orders = null
			if(state.diffDays == 'today'){
				return dispatch('bindRef', { target: 'orders', ref: db.collection('orders').where('merchantId', '==', localStorage.merchantId).where('uniNum', '>=', Number(moment(state.toDay).format('YYYYMMDD') + '001')).where('uniNum', '<=', Number(moment(state.toDay).format('YYYYMMDD') + '999'))})	
			}
			if(state.diffDays == 'yesterday'){
				return dispatch('bindRef', { target: 'orders', ref: db.collection('orders').where('merchantId', '==', localStorage.merchantId).where('uniNum', '>=', Number(moment(state.yesterDay).format('YYYYMMDD') + '001')).where('uniNum', '<=', Number(moment(state.yesterDay).format('YYYYMMDD') + '999'))})	
			}
			if(state.diffDays == 'thisMonth'){
				return dispatch('bindRef', { target: 'orders', ref: db.collection('orders').where('merchantId', '==', localStorage.merchantId).where('completedAt', '>', state.thisMonthStart).where('completedAt', '<', state.thisMonthEnd)})	
			}
			if(state.diffDays == 'last_month'){
				return dispatch('bindRef', { target: 'orders', ref: db.collection('orders').where('merchantId', '==', localStorage.merchantId).where('completedAt', '>', state.lastMonthStart).where('completedAt', '<', state.thisMonthStart)})	
			}
		},

		bindOrders({ state, dispatch }) {
			state.orders = null
			if(state.endDate == state.startDate || state.endDate == null){
				return dispatch('bindRef', { target: 'orders', ref: db.collection('orders').where('merchantId', '==', localStorage.merchantId).where('uniNum', '>=', Number(moment(state.startDate).format('YYYYMMDD') + '001')).where('uniNum', '<=', Number(moment(state.startDate).format('YYYYMMDD') + '999'))})	
			}
			else{
				return dispatch('bindRef', { target: 'orders', ref: db.collection('orders').where('merchantId', '==', localStorage.merchantId).where('uniNum', '>=', Number(moment(state.startDate).format('YYYYMMDD') + '001')).where('uniNum', '<=', Number(moment(state.endDate).format('YYYYMMDD') + '999'))})	
			}
		},

		unbindState: firestoreAction((context, { item }) => {
			if (!context.state[item]) return true
			return context.unbindFirestoreRef(item, () => null)
		}),

		createDocument(store, { col, doc }) {
			const ref = db.collection(col).doc()
			doc.id = ref.id
			return ref.set(doc).then(() => {
				return doc
			}).catch(err => {
				console.error(err)
				return false
			})
		},

		getDocument({ state }, { col, id }) {
			if (!state[col]) return false
			return Object.assign({}, state[col].find(item => item.id === id))
		},

		updateDocument(store, { col, doc }) {
			return db.collection(col).doc(doc.id).update(doc).then(() => {
				return doc
			}).catch(err => {
				console.error(err)
				return false
			})
		},

		deleteDocument(store, { col, id }) {
			return db.collection(col).doc(id).delete().then(() => {
				return true
			}).catch(err => {
				console.error(err)
				return false
			})
		},

		createStorage(store, { col, id, file }) {
			const ref = storage.ref().child(`${col}/${id}`)
			return ref.put(file).then(() => {
				return ref.getDownloadURL()
			})
		},

		createCustomer(store, { customer }) {
			const createCustomer = functions.httpsCallable('createCustomer')
            return createCustomer({ customer })
		}
	},
})
